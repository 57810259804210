#sign-out-btn {
  background-color: white;
  border-radius: 0px;
  border: none;
  box-shadow: none;
  &:hover {
    border: none;
    background-color: rgb(243 244 246);
  }
  &:focus {
    border: none;
    background-color: rgb(243 244 246);
    outline: none;
  }
}
