#dropdown-menu {
  box-shadow: none;
  &:enabled {
    box-shadow: none;
  }
  &:focused {
    box-shadow: none;
  }
  &:hover {
    box-shadow: none;
  }
}

/* dropdown button - hover */
.adminButton {
  &:hover {
    background-color: rgb(243 244 246);
  }
}

.navContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

/* Dropdown purple hover */
.selected-link {
  &:hover {
    background-color: #e1dbf9;
  }
  &:focus {
    border-left: 2px solid #824bd6;
  }
}

.selected-menuItem {
  background-color: #e1dbf9;
  border-left: 2px solid #824bd6;
}

/* Dropdown container */
.menu-styles {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
}
